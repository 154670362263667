import { onDomReady } from '../../components/dynamic/observer';
import { scrollToTarget } from '../../components/scroll/scroll';

class Terms {
  constructor(list) {
    this.list = list;
    this.init();
  }

  init() {
    this.initAnchorLinks();
  }

  initAnchorLinks() {
    Array.from(this.list.querySelectorAll('[data-accordion-block] a'))
      .filter((link) => link.getAttribute('href').startsWith('#'))
      .forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const target = document.querySelector(link.getAttribute('href'));
          if (target) {
            if (!target.classList.contains('_opened')) {
              target.querySelector('[data-accordion-link]').dispatchEvent(new Event('click'));
            }
            scrollToTarget(target, -55);
          }
        });
      });
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-term-list]');
  if (element) {
    const terms = new Terms(element);
  }
});