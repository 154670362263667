import Swiper, {
  Navigation, EffectFade, Controller, Pagination,
} from 'swiper';

Swiper.use([Navigation, EffectFade, Controller, Pagination]);

class CommonSwiper {
  constructor(container, options) {
    this.container = container;
    this.sliderElem = container.querySelector('[data-swiper-slider]');

    this.options = {
      sliderOptions: null,
    };

    this.options = {
      sliderOptions: null,
      ...options,
    };

    this.init();
  }

  init() {
    const config = this.getConfig();
    this.slider = new Swiper(this.sliderElem, config);
  }

  getConfig() {
    this.arrowLeft = this.container.querySelector('[data-arrow-left]');
    this.arrowRight = this.container.querySelector('[data-arrow-right]');
    const pagination = this.container.querySelector('[data-swiper-pagination]');
    const { sliderOptions } = this.options;

    const config = {
      loop: false,
      rewind: false,
      speed: 500,
      slidesPerView: 'auto',
      ...sliderOptions,
    };

    if (this.arrowLeft && this.arrowRight) {
      config.navigation = {
        prevEl: this.arrowLeft,
        nextEl: this.arrowRight,
      };
    }

    if (pagination) {
      config.pagination = {
        el: pagination,
      };
    }

    return config;
  }
}

export default CommonSwiper;