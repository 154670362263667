import axios from 'axios';
import { onDomReady } from '../../components/dynamic/observer';

async function dislike(link) {
  const { href } = link;
  const { targetId, toggleText } = link.dataset;

  const result = await axios.post(href, { target: targetId });
  if (result.data.success) {
    document.querySelectorAll('[data-votes-refresh]').forEach((element) => {
      element.innerHTML = result.data.dislike_count;
      link.innerHTML = toggleText;
      link.classList.add('_already');
    });
  }
}
onDomReady(() => {
  document.querySelectorAll('[data-dislike-link]').forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      dislike(link);
    });
  });
});