import { onDomReady } from '../../components/dynamic/observer';
import Jax from "../../components/jax/jax";

class ScammerList {
  constructor(element) {
    this.element = element;
    this.pagerElem = element.querySelector('[data-capper-pagination]');
    this.apiUrl = element.dataset.apiUrl;
    this.pageSize = 5;
    this.loaded = false;

    this.bind();
    this.request();
  }

  bind() {
    this.bindPagination();
    this.definePageSize();
  }

  request() {
    const instance = this;
    const url = `${this.apiUrl}/${this.pageSize}`;
    const jax = new Jax(url, 'get', false);
    jax.send(new FormData()).then((html) => {
      instance.replaceContent(html);
      if (!instance.loaded) {
        instance.setLoaded();
      }
    });
  }

  setLoaded() {
    this.loaded = true;
    this.element.classList.remove('_loading');
  }

  definePageSize() {
    if (window.innerWidth > 1278) {
      this.pageSize = 10;
    } else {
      this.pageSize = 5;
    }
  }

  bindPagination() {
    const instance = this;
    this.element.querySelectorAll('[data-capper-pagination] a').forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const jax = new Jax(link.href, 'GET', false);
        jax.send(new FormData()).then((html) => {
          instance.replaceContent(html);
        });
      });
    });
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;

    this.replaceList(page);
    this.replacePagination(page);
  }

  replaceList(page) {
    const newData = page.querySelector('[data-scammer-list]');
    const currentData = document.querySelector('[data-scammer-list]');
    currentData.innerHTML = newData.innerHTML;
  }

  replacePagination(page) {
    const newData = page.querySelector('[data-scammer-list-block] [data-capper-pagination]');
    const currentData = this.pagerElem;
    currentData.innerHTML = newData.innerHTML;
    this.bindPagination();
  }
}
onDomReady(() => {
  const element = document.querySelector('[data-scammer-list-block]');
  if (element) {
    new ScammerList(element);
  }
});