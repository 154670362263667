import { onDomReady } from '../../components/dynamic/observer';
import CommonSwiper from './common-swiper';

onDomReady(() => {
  const options = {
    sliderOptions: {
      breakpoints: {
        1: {
          spaceBetween: 20,
          slidesOffsetAfter: 20,
          slidesOffsetBefore: 20,
        },
        1280: {
          spaceBetween: 40,
          slidesOffsetAfter: 40,
          slidesOffsetBefore: 40,
        },
      },
    },
  };

  document.querySelectorAll('[data-article-slider-container]').forEach((container) => {
    const slider = new CommonSwiper(container, options);
  });
});