import { onDomReady } from '../../components/dynamic/observer';
import Jax from '../../components/jax/jax';

class CapperFilter {
  constructor(element) {
    this.element = element;
    this.filterForm = element.querySelector('[data-capper-filter-form]');
    this.list = element.querySelector('[data-cappers-list]');
    this.debounceOrder = 0;
    this.pageSize = 10;
    this.loaded = false;
    this.sortSelect = element.querySelector('[data-sort-select-wrap] select');
    this.sortLinks = element.querySelectorAll('[data-filter-sort-link]');
    this.pagerElem = element.querySelector('[data-capper-pagination]');
    this.readyToNew = true;

    this.init();
  }

  init() {
    this.bind();
    this.definePageSize();
    this.request();
  }

  definePageSize() {
    if (window.innerWidth > 1278) {
      this.pageSize = 10;
    } else {
      this.pageSize = 5;
    }
  }

  bind() {
    this.bindForm();
    this.bindCheckBoxes();
    this.bindSelect();
    this.bindSort();
  }

  bindSort() {
    const instance = this;
    const select = this.sortSelect;
    const links = Array.from(this.sortLinks);
    links.forEach((link) => {
      const downValue = link.dataset.down;
      const upValue = link.dataset.up;
      link.addEventListener('click', (e) => {
        e.preventDefault();
        if (select.value !== downValue && select.value !== upValue) {
          select.value = downValue;
          links.filter((active) => active.classList.contains('_active'))
            .forEach((active) => {
              active.classList.remove('_active');
              active.classList.remove('_up');
            });
          link.classList.add('_active');
        } else if (select.value === downValue) {
          select.value = upValue;
          link.classList.add('_up');
        } else if (select.value === upValue) {
          select.value = downValue;
          link.classList.remove('_up');
        }

        instance.request();
      });
    });
  }

  setLoaded() {
    this.loaded = true;
    this.element.classList.remove('_loading');
  }

  bindSelect() {
    const instance = this;
    this.filterForm.querySelectorAll('select').forEach((select) => {
      select.addEventListener('change', (e) => {
        instance.request();
      });
    });
  }

  bindCheckBoxes() {
    const instance = this;
    const checkboxes = Array.from(this.filterForm.querySelectorAll('input[type=checkbox]'));
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (e) => {
        const target = e.target;
        const { value, name } = target;
        let unchecked = [];
        if (!value || value === '0') {
          unchecked = checkboxes.filter((input) => input.name === name && input !== target);
        } else {
          unchecked = checkboxes.filter((input) => !input.value || input.value === '0');
        }
        unchecked.forEach((input) => {
          input.checked = false;
        });

        instance.debouncedRequest();
      });
    });
  }

  bindForm() {
    const instance = this;
    this.filterForm.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.debouncedRequest();
    });
  }

  debouncedRequest() {
    const instance = this;
    instance.debounceOrder += 1;
    setTimeout(() => {
      instance.debounceOrder -= 1;
      if (!instance.debounceOrder) {
        instance.request();
      }
    }, 1000);
  }

  setReplacing(replacing) {
    if (replacing) {
      this.list.classList.add('_replacing');
      this.element.classList.add('_updating');
    } else {
      this.list.querySelector('[data-cappers-list-replaceble]._out').remove();
      this.list.querySelector('[data-cappers-list-replaceble]._absolute').classList.remove('_absolute');
      this.list.classList.remove('_replacing');
      this.element.classList.remove('_updating');
    }
  }

  request() {
    const instance = this;
    const form = this.filterForm;
    const url = form.getAttribute('action') + `/${this.pageSize}`;
    const jax = new Jax(url, form.getAttribute('method'), false);

    this.setReplacing(true);
    this.oldToOut();

    jax.send(new FormData(form)).then((html) => {
      instance.replaceContent(html);

      setTimeout(() => {
        instance.newToDefault();
      }, 50);

      setTimeout(() => {
        this.setReplacing(false);
      }, 700);

      if (!instance.loaded) {
        instance.setLoaded();
      }
    });
  }

  oldToOut() {
    const oldReplaceble = this.list.querySelector('[data-cappers-list-replaceble]');
    if (oldReplaceble) {
      oldReplaceble.classList.add('_out');
    }
  }

  newToDefault() {
    const newReplaceble = this.list.querySelector('[data-cappers-list-replaceble]._enter');
    if (newReplaceble) {
      newReplaceble.classList.remove('_enter');
    }
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;

    this.replaceList(page);
    this.replacePagination(page);
  }

  replaceList(page) {
    const newReplaceble = page.querySelector('[data-cappers-list] [data-cappers-list-replaceble]');
    newReplaceble.classList.add('_enter');
    newReplaceble.classList.add('_absolute');
    this.list.append(newReplaceble);
  }

  replacePagination(page) {
    const newData = page.querySelector('[data-capper-filterable-block] [data-capper-pagination]');
    const currentData = this.pagerElem;
    currentData.innerHTML = newData.innerHTML;
    this.bindPagination();
  }

  bindPagination() {
    const instance = this;
    this.element.querySelectorAll('[data-capper-pagination] a').forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        this.setReplacing(true);
        this.oldToOut();

        const jax = new Jax(link.href, 'GET', false);
        jax.send(new FormData()).then((html) => {
          instance.replaceContent(html);
          instance.newToDefault();

          setTimeout(() => {
            this.setReplacing(false);
          }, 700);
        });
      });
    });
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-capper-filterable-block]');
  if (element) {
    new CapperFilter(element);
  }
});