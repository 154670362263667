import axios from 'axios';
import { onDomReady } from '../../components/dynamic/observer';

class CapperSearcher {
  constructor(container) {
    this.container = container;
    this.form = container.querySelector('[data-search-form]');
    this.input = container.querySelector('[data-search-input]');
    this.result = container.querySelector('[data-search-result]');

    this.debounceCount = 0;
    this.debounceTime = 1000;

    this.startLength = 2;

    this.bind();
  }

  bind() {
    this.bindInput();
    this.bindForm();
    this.bindDocument();
  }

  bindDocument() {
    const instance = this;
    document.addEventListener('closeSearch', (e) => {
      instance.close();
    });
  }

  bindForm() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
    });
  }

  bindInput() {
    const instance = this;
    this.input.addEventListener('input', (e) => {
      instance.debounceRequest();
    });
  }

  bindCloser() {
    const instance = this;
    const closer = this.result.querySelector('[data-result-closer]');
    if (closer) {
      closer.addEventListener('click', (e) => {
        e.preventDefault();
        instance.close();
      });
    }
  }

  close() {
    this.result.innerHTML = '';
    this.input.value = '';
  }

  debounceRequest() {
    const instance = this;
    this.debounceCount += 1;
    setTimeout(() => {
      instance.debounceCount -= 1;
      if (!instance.debounceCount) {
        this.request();
      }
    }, this.debounceTime);
  }

  async request() {
    const value = this.input.value;
    if (value && value.length > this.startLength) {
      const result = await axios.post(this.form.getAttribute('action'), { value });
      this.replaceContent(result.data);
    }
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const newData = page.querySelector('[data-search-result]');
    this.result.innerHTML = newData.innerHTML;
    this.afterReplace();
  }

  afterReplace() {
    this.bindCloser();
  }
}
onDomReady(() => {
  document.querySelectorAll('[data-capper-searcher]').forEach((element) => {
    const capperSearcher = new CapperSearcher(element);
  });

  document.addEventListener('click', (e) => {
    const outer = e.target.closest('[data-capper-searcher]');
    if (!outer) {
      document.dispatchEvent(new Event('closeSearch'));
    }
  });
});