import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);

      this.content.querySelectorAll('._need-remove')
        .forEach((contentElement) => contentElement.classList.remove('_need-remove'));
    },
  });
  return false;
});