import axios from 'axios';
import { onDomReady } from '../../components/dynamic/observer';
import element from "../../components/accordion/objects/element";

class StarsBlock {
  constructor(block) {
    this.block = block;
    this.currentStar = parseInt(block.dataset.currentStar, 10);
    this.baseUrl = block.dataset.url;
    this.target = block.dataset.target;

    this.stars = Array.from(block.querySelectorAll('[data-star]'))
      .map((element) => ({
        number: parseInt(element.dataset.star, 10),
        item: element,
        link: element.querySelector('a'),
      }));

    this.bind();
  }

  bind() {
    this.bindLinks();
  }

  bindLinks() {
    const instance = this;
    this.stars.forEach((star) => {
      star.link.addEventListener('click', (e) => {
        e.preventDefault();
        instance.currentStar = star.number;
        instance.stars
          .forEach((other) => {
            if (other.number <= star.number) {
              other.item.classList.add('_fill');
            } else {
              other.item.classList.remove('_fill');
            }
          });
        instance.sendStars(star.number);
      });
    });
  }

  async sendStars(number) {
    const result = await axios.post(this.baseUrl, {
      target: this.target,
      number,
    });
    this.handleResponse(result);
  }

  handleResponse(response) {
    if (response.data.success) {
      const newVoteCount = response.data.star_response.vote_count;
      const newRating = Math.round(parseFloat(response.data.star_response.new_rating) * 10) / 10;
      document.querySelectorAll('[data-votes-refresh]')
        .forEach((element) => {
          element.innerHTML = newVoteCount;
        });
      document.querySelectorAll('[data-rating-refresh]')
        .forEach((element) => {
          element.innerHTML = newRating.toFixed(1);
        });
    }
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-stars-block]').forEach((element) => {
    const starsBlock = new StarsBlock(element);
  });
});