require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');

require('fslightbox');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone');
// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');

require('../fonts/Effra/stylesheet.css');
require('../fonts/Montserrat/stylesheet.css');

require('./header/header');

require('./cappers/capper-filter');
require('./cappers/scammer-list');
require('./cappers/stars');
require('./common/floating-field');
require('./sliders/index');
require('./cappers/dislikes');

require('./cappers/searcher');
require('./terms/terms');