import Jax from "../../components/jax/jax.js";
import IMask from "imask";

const initPhoneField = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    input.dataset.initialized = "true";

    const mask = IMask(input, {
      mask: '+{7} (000) 000-00-00'
    });

    mask.on("complete", () => {
      if (input.dataset.leadUrl) {
        let jax = new Jax(input.dataset.leadUrl, 'POST');
        jax.send({
          phone: input.value
        });
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", initPhoneField);
document.addEventListener("DOMContentMutated", initPhoneField);